.footer{
    width: 100vw;
    display: flex;
    justify-content: center;
}

.footer-inner{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}

.footer-content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: 2px solid #000000;
}

/*============Media Queries==========*/

@media (min-width: 576px){
    .footer-inner{
        width: 500px;
    }
}