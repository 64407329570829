.schedule{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

/*============Schedule Content============*/

.schedule-content{
    width: 90%;
    padding-top: 80px;
    padding-bottom: 40px;
    box-sizing: border-box;
    align-self: center;
}

.schedule-content > h2,h3{
    text-align: center;
}

.schedule-content > h2{
    margin-bottom: 20px;
}

/*============Schedule Types===============*/

.schedule-types{
    width: 90%;
    align-self: center;
    background: rgba(143, 137, 137, 0.3);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
}

.schedule-types > div{
    background-color: grey;
    padding: 5px;
    border: 1px solid black;
    border-radius: 10px;
    text-align: center;
    font-size: 1.2rem;
    padding-bottom: 20px;
}

.schedule-types :nth-child(1){
    margin-bottom: 40px;
}

/*==========Media Queries===========*/

@media(min-width: 850px){
    .schedule-content,
    .schedule-types{
        width: 80%;
    }
}

/*===================================*/

@media(min-width: 992px){
    .schedule-content,
    .schedule-types{
        width: 70%;
    }
}

/*===================================*/

@media(min-width: 1400px){
    .schedule-content,
    .schedule-types{
        width: 1000px;
    }
}