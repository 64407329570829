.illness-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.illness-list-inner{
    padding-top: 70px;
    width: 95%;
    padding-left: 20px;
    box-sizing: border-box;
}