/*Used for styling both privacy policy and terms of service js files*/

.privacy-policy,
.terms-of-service{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-section-p{
    padding-top: 100px;
}

.terms{
    padding-top: 100px;
    padding-bottom: 50px;
    color: green;
    text-align: center;
}

.sections{
    width: 90%;
    margin-bottom: 50px;
}

.page-h1{
    text-align: center;
    margin-bottom: 10px;
    color: green;
}

/*===========Media Query============*/

@media (min-width: 992px){
    .sections{
        width: 80%;
    }
}

/*==================================*/

@media (min-width: 1200px){
    .sections{
        width: 70%;
    }
}

/*==================================*/

@media (min-width: 1500px){
    .sections{
        width: 60%;
    }
}