*{
    margin: 0;
    padding: 0;
}

.App {
    font-family: sans-serif;
    font-weight: bold;
    overflow-x: hidden;
    width: 100vw;
}
